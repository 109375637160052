import React from 'react'
import SEO from '../components/App/SEO'
import Layout from '../components/App/Layout'
import Navbar from "../components/App/Navbar"
import Footer from "../components/App/Footer"
import PricingComp from '../components/Pricing/PricingComp'
import { graphql } from 'gatsby'

const Pricing = ({data}) => {
    return (
        <Layout env={data.site.siteMetadata.env}>
            <Navbar />
            <SEO 
                postTitle="BlueMail Pricing Plans"
                postDescription="BlueMail Plus and Business Pro are premium packages, while the BlueMail standard package is Free and ad-free."
                postImage="/img/OG/og_image_Pricing-n.png"
                postURL="pricing"
                postSEO
            />
            <PricingComp />
            <Footer />
        </Layout>
    );
}

export default Pricing;

export const query = graphql`
query PricingPageQuery {
  site {
      siteMetadata {
        title
        env
      }
    }
  }
`